import CONSTANTS from '../Constants';
import api from '../Utils/api';

interface DesignerDTO {
    id: string;
    name: string;
}

interface CategoryDTO {
    id: string;
    name: string;
    garment_count: number;
    sub_categories?: SubCategoryDTO[];
}

interface SubCategoryDTO {
    id: string;
    name: string;
    garment_count: number;
}

export interface ImageDTO {
    created: string;
    created_by: string;
    updated: string;
    updated_by: string;
    id: string;
    content_type: string;
    tag: string;
    url: string;
    size: number;
    height: number;
    width: number;
}

interface VariantDTO {
    color: string;
    size: string;
    price: number;
}

export interface GarmentDTO {
    id: string;
    title: string;
    designer: DesignerDTO;
    categories: CategoryDTO[];
    sub_categories: SubCategoryDTO[];
    description: string;
    thumbnail: ImageDTO;
    images: ImageDTO[];
    variants: VariantDTO[];
}

interface DesignersResponseDTO {
    designers: DesignerDTO[];
}

interface CategoriesResponseDTO {
    categories: CategoryDTO[];
}

interface GarmentsResponseDTO {
    garments: GarmentDTO[];
}

const PROTEA_BASE_URL = '/v1/protea';

const getTokenFromStorage = (): Promise<string> => {
    const MAX_WAIT_TIME = 2000;

    return new Promise((resolve, reject) => {
        const startTime = Date.now();

        const checkToken = () => {
            const token = localStorage.getItem(CONSTANTS.LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
            if (token) {
                resolve(token);
            } else if (Date.now() - startTime >= MAX_WAIT_TIME) {
                reject(new Error('Token not found within the allowed time.'));
            } else {
                setTimeout(checkToken, 100);
            }
        };

        checkToken();
    });
};

const garmentService = {
    fetchCategories: async (): Promise<CategoriesResponseDTO> => {
        return api.get(`${PROTEA_BASE_URL}/categories`, await getTokenFromStorage());
    },
    fetchDesigners: async (): Promise<DesignersResponseDTO> => {
        return api.get(`${PROTEA_BASE_URL}/designers`, await getTokenFromStorage());
    },
    fetchAllGarments: async (): Promise<GarmentsResponseDTO> => {
        return api.get(`${PROTEA_BASE_URL}/garments`, await getTokenFromStorage());
    },
    fetchGarment: async (garmentId: string): Promise<GarmentDTO> => {
        return api.get(`${PROTEA_BASE_URL}/garment/${garmentId}`, await getTokenFromStorage());
    },
    fetchGarmentsByDesigner: async (designerId: string): Promise<GarmentsResponseDTO> => {
        return api.get(`${PROTEA_BASE_URL}/designer/${designerId}`, await getTokenFromStorage());
    },
    fetchGarmentsByCategory: async (categoryId: string): Promise<GarmentsResponseDTO> => {
        return api.get(
            `${PROTEA_BASE_URL}/garments/category/${categoryId}`,
            await getTokenFromStorage()
        );
    },
    fetchGarmentsSubcategories: async (
        categoryId: string,
        subcategoryId: string
    ): Promise<GarmentsResponseDTO> => {
        return api.get(
            `/garments/category/${categoryId}/${subcategoryId}`,
            await getTokenFromStorage()
        );
    },
};

export default garmentService;
